// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-coconstruccionleyes-js": () => import("./../../../src/pages/coconstruccionleyes.js" /* webpackChunkName: "component---src-pages-coconstruccionleyes-js" */),
  "component---src-pages-consultadigital-js": () => import("./../../../src/pages/consultadigital.js" /* webpackChunkName: "component---src-pages-consultadigital-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-presupuestoparticipativo-js": () => import("./../../../src/pages/presupuestoparticipativo.js" /* webpackChunkName: "component---src-pages-presupuestoparticipativo-js" */),
  "component---src-pages-seguimientometas-js": () => import("./../../../src/pages/seguimientometas.js" /* webpackChunkName: "component---src-pages-seguimientometas-js" */),
  "component---src-pages-votacionautoridades-js": () => import("./../../../src/pages/votacionautoridades.js" /* webpackChunkName: "component---src-pages-votacionautoridades-js" */)
}

